import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './index.styl';

export default function Button2({ size, ...props }) {
  return (
    <button
      {...props}
      className={cx(props.className, styles[size], styles.button)}
    />
  );
}

Button2.propTypes = {
  size: PropTypes.oneOf(['medium', 'large']),
  className: PropTypes.string,
};
