import PropTypes from 'prop-types';
import cx from 'classnames';
import { useEffect, useState, useRef } from 'react';

import { resolveScopedStyles } from 'core/utils/styled-jsx';
import { withBreakpoint } from 'core/components/breakpoint';

import Socializator from 'core/components/Socializator';
import ThemeSwitch from 'core/components/ThemeSwitch';
import Modal from 'core/components/Modal';

import Button2 from '../../Button2';

import Share from '../../images/Share';
import chastushka1 from '../../images/chastushka1.jpg';
import chastushka2 from '../../images/chastushka2.jpg';
import chastushka3 from '../../images/chastushka3.jpg';
import chastushka4 from '../../images/chastushka4.jpg';
import chastushka5 from '../../images/chastushka5.jpg';
import chastushka6 from '../../images/chastushka6.jpg';

import styles from './index.styl';

const images = {
  0: chastushka1,
  1: chastushka2,
  2: chastushka3,
  3: chastushka4,
  4: chastushka5,
  5: chastushka6,
};


function ChastushkiCard(props, context) {
  const {
    chastushka: {
      text,
    },
    index,
  } = props;

  const {
    host,
  } = context;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = useRef(null);

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const options = {
    description: 'Проявите заботу — перешлите близким. И заходите к нам, тут ещё много полезного и интересного про защиту от телефонных мошенников',
    image: `https://${host}${images[index]}`,
  };

  const settings = {
    'buttons': [
      'vkontakte',
      'odnoklassniki',
      'telegram',
    ],
    'vkontakte': options,
    'odnoklassniki': options,
    'telegram': options,
  };

  const scopedStyles = resolveScopedStyles(
    <scope>
      <style jsx>{`
      .modalOverlay
        background-color transparent

      .modalWindow
        background-color transparent
        max-width 670px
        width 100%
        box-sizing border-box
        overflow visible

        :global(.mobile) &
          max-width 80%

      .closeIcon
        width 14px
        height 14px
    `}</style>
    </scope>
  );

  useEffect(() => {
    document.body.style.overflow = isModalOpen ? 'hidden' : '';

    const handleClickOutside = (event) => {
      if (isModalOpen && modalRef.current && !modalRef.current.contains(event.target)) {
        setIsModalOpen(false);
      }
    };

    const handleEscapeKeyDown = (event) => {
      if (isModalOpen && event.key === 'Escape') {
        setIsModalOpen(false);
      }
    };

    if (isModalOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleEscapeKeyDown);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKeyDown);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKeyDown);
    };
  }, [isModalOpen]);

  return (
    <div className={cx(styles.dittiesCard, index % 2 === 0 ? styles._even : styles._odd)}>
      <div className={styles.text}>{text}</div>
      <Button2 className={styles.button} onClick={handleOpen}>Поделиться <Share /></Button2>
      <Modal
        isOpen={isModalOpen}
        onClose={handleClose}
        scopedStyles={scopedStyles}
      >
        <div className={styles.modal} ref={modalRef}>
          <div className={styles.share}>Поделиться</div>
          <div className={styles.icons}>
            <ThemeSwitch name='mamonty'>
              <Socializator {...settings} title='Частушки о кибербезопасности от сериала “Мамонты” и Окко' />
            </ThemeSwitch>
          </div>
        </div>
      </Modal>
    </div>
  );
}

ChastushkiCard.propTypes = {
  chastushka: PropTypes.object,
  index: PropTypes.number,
};

ChastushkiCard.contextTypes = {
  host: PropTypes.string,
};

export default withBreakpoint(ChastushkiCard);
