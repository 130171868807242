import PropTypes from 'prop-types';
import { withBreakpoint } from 'core/components/breakpoint';

import styles from './index.styl';

function AboutSeries({ isMobile }) {
  return (
    <section className={styles.aboutWrapper}>
      <div className={styles.about}>
        <div className={styles.aboutText}>
          <p className={styles.header}>Сюжет</p>
          <p>
            Пенсионер Николай Николаевич продаёт свою квартиру в&nbsp;родном городе и&nbsp;перебирается к&nbsp;дочке и&nbsp;внучке в&nbsp;Новую Москву.
          </p>
          <p>
            План купить жилье с&nbsp;ними по&nbsp;соседству проваливается&nbsp;&mdash; он&nbsp;попадается на&nbsp;уловку телефонного мошенника и&nbsp;переводит на&nbsp;&laquo;безопасный счет&raquo; все свои деньги. После обращения в&nbsp;полицию становится очевидно, что шанс вернуть украденное равен нулю.
          </p>
          <p>
            Но&nbsp;пенсионер не&nbsp;отчаивается, а&nbsp;решает окунуться в&nbsp;прогрессивный мир киберпреступности: освоить даркнет, научиться делать пробивы телефонных биллингов, скачивать базы данных, подделывать документы и&nbsp;встречаться с&nbsp;барыгами.
          </p>
        </div>
        <div className={styles.iframe}>
          <iframe
            src='https://vk.com/video_ext.php?oid=-47393707&id=456244633'
            width={isMobile ? '330' : '785'}
            height={isMobile ? '185' : '447'}
            allow='autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;'
            frameBorder='0'
            allowFullScreen
            title='Трейлер'
            loading='lazy'
          />
        </div>
      </div>
    </section>
  );
}

AboutSeries.propTypes = {
  isMobile: PropTypes.bool,
};


export default withBreakpoint(AboutSeries);
