import PropTypes from 'prop-types';
import { withBreakpoint, Desktop, Mobile } from 'core/components/breakpoint';

import CrosswordDisplay from './CrosswordDisplay';

import stoyanov from '../images/stoyanov.png';
import stoyanovMobile from '../images/stoyanov_mobile.png';
import elizaveta from '../images/elizaveta.png';
import elizavetaMobile from '../images/elizaveta_mobile.png';
import arrow from '../images/arrow.png';

import styles from './index.styl';

const description = (
  <div className={styles.description}>
    Кликай на цифры, заполняй пропуски во фразах и вноси ответы в кроссворд.
    Если будет трудно - можно снова обратиться к словарю или попросить помощи у своих близких из другого поколения,
    им точно будет приятно провести время вместе и все вам объяснить!
  </div>
);

function Crossword({ isMobile }) {
  return (
    <div className={styles.crossword}>
      <div className={styles.wrapper}>
        <Desktop>
          <div className={styles.blockHeader}>
            <div className={styles.num}>2</div>
            <img src={arrow} />
            <div className={styles.title}>Помогайте друг другу</div>
          </div>
        </Desktop>
        <Mobile>
          {description}
        </Mobile>
        <div className={styles.main}>
          <div className={styles.left}>
            <img
              src={isMobile ? stoyanovMobile : stoyanov}
              alt='Yuriy Stoyanov'
              className={styles.img}
            />
          </div>

          <CrosswordDisplay />

          <div className={styles.right}>
            <Desktop>{description}</Desktop>
            <div className={styles.image}>
              <img
                src={isMobile ? elizavetaMobile : elizaveta}
                alt='Elizaveta Ishchenko'
                className={styles.img}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Crossword.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(Crossword);
