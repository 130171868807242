import PropTypes from 'prop-types';
import { useCallback, useRef } from 'react';

import { withBreakpoint, Desktop, Mobile } from 'core/components/breakpoint';

import Swipe, { Slide } from 'core/components/Swipe';

import ChastushkiCard from './ChastushkiCard';

import ArrowRight from '../images/ArrowRight';
import arrow from '../images/arrow.png';

import styles from './index.styl';

const chastushkiData = [
  {
    text: 'Мне из банка позвонил\nОчень милый Даниил.\nЖалко этого юнца —\nБыл отправлен в три конца!',
  },
  {
    text: 'Мамонтами называют\nВсех, кто кибербез не знает.\nМамонтом быть не хочу —\nТехнологии учу!',
  },
  {
    text: 'Мне из ФСБ звонили —\nСпрашивали карты код.\nВот преступность обленилась —\nТретий год такой развод!',
  },
  {
    text: 'На моем счету случилась\n«Странная транзакция».\nРаньше я бы суетился,\nТеперь — ноль реакции!',
  },
  {
    text: 'Я мошенников люблю,\nС ними долго говорю.\nС пользой провожу досуг —\nТрачу время этих …',
  },
  {
    text: 'Я все деньги переслал\nПринцу африканскому!\nВот всегда всем сердцем знал —\nВсе они засранцы там!',
  },
];


function Chastushki({ isMobile }) {
  const swipeRef = useRef(null);

  const nextHandler = useCallback(() => swipeRef.current.next(), []);
  const prevHandler = useCallback(() => swipeRef.current.prev(), []);

  return (
    <div className={styles.ditties}>
      <div className={styles.container}>

        <div className={styles.wrap}>
          <div className={styles.header}>
            <div className={styles.num}>4</div>
            <Desktop><img src={arrow} /></Desktop>
            <div className={styles.titleWrapper}>
              <div className={styles.title}>Соединяйте пользу с развлечением</div>
              <div className={styles.description}>Например, отличный способ рассказать родственникам о кибербезе — тематические частушки!</div>
            </div>
          </div>

          <div className={styles.chastushki}>
            <Mobile><div className={styles.age}>18+</div></Mobile>
            <Swipe
              loop
              ref={swipeRef}
              width='100%'
              slidesPerView={1.15}
              centeredSlides
              containerClass={styles.containerClass}
            >
              {chastushkiData.map((chastushka, index) => (
                <Slide {...!isMobile && { width: '100%' }} key={index}>
                  <ChastushkiCard chastushka={chastushka} index={index} />
                </Slide>
              )
              )}
            </Swipe>

            <Desktop>
              <div className={styles.arrow} onClick={prevHandler}>
                <ArrowRight width={isMobile ? 32 : 64} height={isMobile ? 32 : 64} />
              </div>
              <div className={styles.arrow} onClick={nextHandler}>
                <ArrowRight width={isMobile ? 32 : 64} height={isMobile ? 32 : 64} />
              </div>
            </Desktop>

            <div className={styles.iframe}>
              <iframe
                src='//ok.ru/videoembed/9382983240248'
                width={isMobile ? '330' : '550'}
                height={isMobile ? '330' : '550'}
                allow='autoplay'
                frameBorder='0'
                allowFullScreen
                title='Частушки'
                loading='lazy'
              />
              <Desktop><div className={styles.age}>18+</div></Desktop>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Chastushki.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(Chastushki);
