import { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { debounce } from 'core/libs/lodash';


import { resolveScopedStyles } from 'core/utils/styled-jsx';

import { withBreakpoint } from 'core/components/breakpoint';
import Swipe, { Slide } from 'core/components/Swipe';
import Link from 'core/components/Link';
import Modal from 'core/components/Modal';
import Button2 from '../Button2';


import ArrowRight from '../images/ArrowRight';
import CloseRound from '../images/CloseRound';


import styles from './index.styl';

const data = [
  {
    name: '@urazalin.rustam.blog',
    video: require('./videos/urazalin.mp4'),
    image: require('./images/urazalin.jpg'),
  }, {
    name: '@zhenatnamarine',
    video: require('./videos/zhenatnamarine.mp4'),
    image: require('./images/zhenatnamarine.jpg'),
  }, {
    name: '@polyaizderevki',
    video: require('./videos/polyaizderevki.mp4'),
    image: require('./images/polyaizderevki.jpg'),
  }, {
    name: '@kuzmummy',
    video: require('./videos/kuzmummy.mp4'),
    image: require('./images/kuzmummy.jpg'),
  }, {
    name: '@elderlypeople58',
    video: require('./videos/elderlypeople58.mp4'),
    image: require('./images/elderlypeople58.jpg'),
  }, {
    name: '@tarnovita',
    video: require('./videos/tarnovita.mp4'),
    image: require('./images/tarnovita.jpg'),
  },
];

const scopedStyles = resolveScopedStyles(
  <scope>
    <style jsx>{`
      .modalOverlay
        background-color rgba(0, 0, 0, .9)

      .modalWindow
        background-color transparent
        max-width none
        width 100%
        height 100%
        box-sizing border-box
        overflow visible
        display flex
        align-items center
    `}</style>
  </scope>
);

function Videos({ isMobile }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);

  const swipeRef = useRef();
  const modalSwipeRef = useRef();
  const modalVideosRefs = useRef([]);

  const nextHandler = () => {
    swipeRef.current.next();
  };

  const prevHandler = () => {
    swipeRef.current.prev();
  };

  const modalNextHandler = () => {
    modalSwipeRef.current.next();
    swipeRef.current.next();
  };

  const modalPrevHandler = () => {
    modalSwipeRef.current.prev();
    swipeRef.current.prev();
  };

  const onActiveIndexChange = debounce(({ realIndex }) => {
    setActiveSlide(realIndex);
  }, 100);

  const onModalActiveIndexChange = debounce(({ realIndex }) => {
    setActiveSlide(realIndex);

    const currentVideo = modalVideosRefs.current[realIndex];

    if (!currentVideo) return;

    modalVideosRefs.current.forEach(el => {
      el.pause();
    });

    modalVideosRefs.current[realIndex].play();
  }, 100);

  const onSlideClick = i => () => {
    if (i === activeSlide) {
      setIsModalOpen(true);
      return;
    }

    swipeRef.current.swiper.slideToLoop(i);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    // В LB это не работает, почему-то
    document.body.style.overflow = isModalOpen ? 'hidden' : '';
  }, [isModalOpen]);

  return (
    <section className={styles.videos}>
      <div className={styles.header}>
        <h2 className={styles.title}>#круг_заботы</h2>
        <p className={styles.desc}>По случаю выхода сериала «Мамонты» известные блогеры запустили челлендж, в котором, показали как они заботятся о своих родных и близких из старшего поколения</p>
      </div>
      <div className={styles.slider}>
        <Swipe
          loop
          ref={el => (swipeRef.current = el)}
          centeredSlides
          containerClass={styles.swipe}
          slidesPerView='auto'
          onActiveIndexChange={onActiveIndexChange}
          {...isMobile && {
            centeredSlides: false,
          }}
        >
          {data.map((bloger, i) => {
            return (
              <Slide key={i}>
                <div className={styles.slide} onClick={onSlideClick(i)}>
                  <div className={styles.video}>
                    <img
                      src={bloger.image}
                      alt={bloger.name}
                      width={isMobile ? 250 : 320}
                      height={isMobile ? 453 : 640}
                    />
                  </div>
                  <div className={styles.nickname}>
                    {bloger.name}
                  </div>
                </div>
              </Slide>
            );
          })}
        </Swipe>

        {!isMobile && !isModalOpen && (
          <>
            <div className={cx(styles.arrow, styles.leftArrow)} onClick={prevHandler}>
              <ArrowRight width='64' height='64' />
            </div>
            <div className={styles.arrow} onClick={nextHandler}>
              <ArrowRight width='64' height='64' />
            </div>
          </>
        )}
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        enableCloseOnEsc
        scopedStyles={scopedStyles}
      >
        <CloseRound
          width='64'
          height='64'
          onClick={closeModal}
          className={styles.modalClose}
        />
        <Swipe
          loop
          ref={el => (modalSwipeRef.current = el)}
          centeredSlides
          defaultItemIndex={activeSlide}
          slidesPerView={1}
          onActiveIndexChange={onModalActiveIndexChange}
        >
          {data.map((bloger, i) => {
            return (
              <Slide key={i}>
                <div className={styles.modalSlide}>
                  <div className={styles.modalVideo}>
                    <video
                      playsInline
                      controls
                      ref={el => (modalVideosRefs.current[i] = el)}
                    >
                      <source src={bloger.video} type='video/mp4' />
                    </video>
                  </div>
                </div>
              </Slide>
            );
          })}
        </Swipe>
        {!isMobile && (
          <>
            <div className={cx(styles.arrow, styles.leftArrow)} onClick={modalPrevHandler}>
              <ArrowRight width='64' height='64' />
            </div>
            <div className={styles.arrow} onClick={modalNextHandler}>
              <ArrowRight width='64' height='64' />
            </div>
          </>
        )}
      </Modal>
      <div className={styles.footer}>
        <Link
          type='blank'
          to='https://merch.okko.tv/stickers/tproduct/844942614-168057658282-stikerpak-dlya-zaschiti-cvv-koda'
          className={styles.buttonLink}
        >
          <Button2 size='large'>Купить стикеры для&nbsp;банковских карт</Button2>
        </Link>
      </div>
    </section>
  );
}

Videos.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(Videos);
