import PropTypes from 'prop-types';
import DictionaryItem from '../DictionaryItem';
import cx from 'classnames';

import styles from './index.styl';

function DictionaryCard({ type, image, words }) {
  const isZoomerCard = type === 'zoomer';
  const header = isZoomerCard ? 'зумера' : 'бумера';

  return (
    <div className={cx(styles.dictionaryCardContainer, styles[`${type}Card`])} >
      <div className={styles.headerWrapper}>
        <img
          className={styles.cardImage}
          src={image}
          width={isZoomerCard ? '218' : '176'}
          height={isZoomerCard ? '258' : '219'}
          alt=''
        />
        <div className={styles.headerText}>Словарь<br />{header}</div>
      </div>
      <dl className={styles.difinitionList}>
        <div className={styles.diffinionListColumn}>
          {words.map(({ word, definition, mark }) => (
            <DictionaryItem
              key={word}
              word={word}
              definition={definition}
              mark={mark}
            />
          ))}
        </div>
      </dl>

    </div>
  );
}

DictionaryCard.propTypes = {
  type: PropTypes.oneOf(['zoomer', 'boomer']),
  image: PropTypes.node,
  words: PropTypes.arrayOf(PropTypes.object),
};


export default DictionaryCard;
