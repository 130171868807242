import React, { useEffect, useState, useRef, useCallback } from 'react';
import cx from 'classnames';

import { debounce } from 'core/libs/lodash';

import Modal from '../Modal';
import { data, grid } from '../crosswordData';
import { getCurrentWord } from '../utils';

import styles from './index.styl';


function CrosswordDisplay() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [crosswordData, setCrosswordData] = useState(grid);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(null);
  const [isCorrectAnswer, setCorrectAnswer] = useState(null);
  const [currentUserAnswer, setCurrentUserAnswer] = useState([]);
  const [isAllAnswersFilled, setIsAllAnswersFilled] = useState(false);
  const modalRef = useRef(null);

  // Получение данных из localStorage;
  useEffect(() => {
    const savedCrossword = localStorage.getItem('crossword');
    if (savedCrossword) {
      setCrosswordData(JSON.parse(savedCrossword));
    }
    setIsLoaded(true);
  }, []);

  // Сохранение данных с задержкой в localStorage;
  useEffect(() => {
    if (isLoaded) {
      const saveToLocalStorage = debounce(() => {
        localStorage.setItem('crossword', JSON.stringify(crosswordData));
      }, 300);

      saveToLocalStorage();
    }
  }, [crosswordData, isLoaded]);

  // Открытие модального окна
  const handleOpen = useCallback((questionIndex, rowIndex, colIndex) => {
    if (questionIndex) {
      setIsModalOpen(true);
      setCurrentQuestionIndex(questionIndex);
      setCorrectAnswer(null);
    } else if (rowIndex !== null && colIndex !== null) {
      const words = crosswordData[rowIndex][colIndex]?.words || [];
      if (words.length === 1) {
        setCurrentQuestionIndex(words[0]);
        setIsModalOpen(true);
        setCorrectAnswer(null);
      }
    }
  }, [crosswordData]);

  // Закрытие модального окна
  const handleClose =  useCallback(() => {
    setIsModalOpen(false);
    setCurrentQuestionIndex(null);
  }, []);

  // Записываем в текущий ответ пользователя буквы, которые уже отгаданы
  useEffect(() => {
    const word = getCurrentWord(data[currentQuestionIndex], crosswordData);
    setCurrentUserAnswer(word);
  }, [currentQuestionIndex, crosswordData]);

  // Проверка клика вне области модального окна
  useEffect(() => {
    document.body.style.overflow = isModalOpen ? 'hidden' : '';

    const handleClickOutside = (event) => {
      if (isModalOpen && modalRef.current && !modalRef.current.contains(event.target)) {
        setIsModalOpen(false);
      }
    };

    if (isModalOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isModalOpen, setIsModalOpen]);

  const commonProps = {
    isModalOpen,
    handleClose,
    setIsModalOpen,
    currentQuestionIndex,
    data,
    crosswordData,
    currentUserAnswer,
    isCorrectAnswer,
    setCurrentUserAnswer,
    setCrosswordData,
    setCorrectAnswer,
    modalRef,
    isAllAnswersFilled,
    setIsAllAnswersFilled,
    setCurrentQuestionIndex,
  };

  return (
    <div className={styles.crossword}>
      {crosswordData?.map((row, rowIndex) => (
        <div className={styles.row} key={rowIndex}>
          {row.map((cell, colIndex) => {
            const isQuestionNum = typeof cell === 'number';
            const key = `${rowIndex}-${colIndex}`;

            if (cell === null) {
              return (
                <div key={key} className={cx(styles.cell, styles._empty)} />
              );
            }

            return (
              <div
                key={key}
                className={cx(styles.cell,
                  isQuestionNum && styles._isNum,
                )}
              >
                {isQuestionNum ?
                  <>
                    <button
                      onClick={() => handleOpen(cell, null, null)}
                      className={styles.button}
                    >
                      {cell}
                    </button>
                    <Modal {...commonProps} />
                  </>
                  : <button className={styles.letter} onClick={() => handleOpen(null, rowIndex, colIndex)}>{cell?.letter}</button>
                }
              </div>
            );
          }
          )}
        </div>
      ))}
    </div>
  );
}

export default CrosswordDisplay;
