import PropTypes from 'prop-types';
import { withBreakpoint } from 'core/components/breakpoint';

import DictionaryCard from './DictionaryCard';
import DictionaryCardMobile from './DictionaryCard/mobile';

import boomer from '../images/boomer.png';
import boomerMobile from '../images/boomer_mobile.png';
import zoomer from '../images/zoomer.png';
import zoomerMobile from '../images/zoomer_mobile.png';

import { zoomerDic, boomerDic, boomerDicMobile, zoomerDicMobile } from './dictinary';

import styles from './index.styl';

function Dictionary({ isDesktop }) {
  const boomerWords = isDesktop ? boomerDic : boomerDicMobile;
  const zoomerWords = isDesktop ? zoomerDic : zoomerDicMobile;
  return (
    <div className={styles.dictionaryWrapper}>
      <section className={styles.dictionary}>
        <div className={styles.sectionText}>
          <p className={styles.headerSectionText}>Что такое забота о близких сегодня?</p>
          <p className={styles.headerSectionDesc}>В&nbsp;честь выхода сериала &laquo;Мамонты&raquo; мы&nbsp;собрали для вас несколько способов проявить внимание и&nbsp;любовь к&nbsp;семье в&nbsp;незнакомом им&nbsp;мире новых технологий</p>
        </div>
        <div className={styles.aboba}>
          <p className={styles.arrowNumber}>1</p>
          <p className={styles.learnSpeak}>Учитесь говорить на одном языке</p>
        </div>

        {isDesktop && (
          <div className={styles.cardWrapper}>
            <DictionaryCard type='boomer' image={boomer}
              words={boomerWords}
            />
            <DictionaryCard type='zoomer' image={zoomer}
              words={zoomerWords}
            />
          </div>
        )}

        {!isDesktop && (
          <div className={styles.cardWrapper}>
            <DictionaryCardMobile
              type='boomer'
              image={boomerMobile}
              words={boomerDicMobile}
            />
            <DictionaryCardMobile
              type='zoomer'
              image={zoomerMobile}
              words={zoomerDicMobile}
            />
            <div className={styles.aboba}>
              <p className={styles.arrowNumber}>2</p>
              <p className={styles.learnSpeak}>Помогайте друг другу</p>
            </div>
          </div>
        )}
      </section>
    </div>
  );
}

Dictionary.propTypes = {
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(Dictionary);
