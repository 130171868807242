import Page from 'core/components/Page';
import LegalPageCore from 'core/components/LegalPage';

import { NarrowWrapper } from 'site/components/Wrappers';

import TermsOfUse from './TermsOfUse';
import Privacy from './Privacy';


const description = {
  'mailing-rules': 'Все правила рассылок определяют общие условия рассылки сообщений пользователям, осуществляемой провайдером и/или партнерами.',
  'terms-of-use': 'Определяют условия, на которых юридическое лицо предоставляет сайт.',
  'privacy': 'Политика конфиденциальности содержит сведения о реализуемых требованиях к защите персональных данных Пользователей на сайте OKKOLOKINO.',
};


/**
 * @author Baranov Igor <i.baranov@rambler-co.ru>
 */

function LegalPage() {
  return (
    <Page
      description='Вся важная правовая информация OKKOLOKINO представлена на этой странице: условия использования, правила рассылок и политика конфиденциальности.'
      skipRcm
    >
      <NarrowWrapper>
        <LegalPageCore
          termsOfUseContent={<TermsOfUse />}
          privacyContent={<Privacy />}
          description={description}
        />
      </NarrowWrapper>
    </Page>
  );
}


LegalPage.displayName = 'LegalPage';

export default LegalPage;
