import PropTypes from 'prop-types';
import { useRef, useEffect, useState } from 'react';
import cx from 'classnames';

import { withBreakpoint } from 'core/components/breakpoint';
import skip from 'core/resolver/skip';

import WatchButton from './WatchButton';
import Okko from 'site/icons/Okko';

import styles from './index.styl';

import titleImg from '../images/mamonty_title.png';

const link = 'https://okko.tv/serial/mamonty?utm_source=okkolokino&utm_medium=site&utm_campaign=mamont';

function Hero({ isMobile }) {
  const buttonRef = useRef(null);
  const [isButtonVisible, setIsButtonVisible] = useState(false);

  useEffect(() => {
    const currentButton = buttonRef.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsButtonVisible(!entry.isIntersecting);
      },
      {
        root: null,
        threshold: 0.1,
      }
    );

    if (currentButton) {
      observer.observe(currentButton);
    }

    return () => {
      if (currentButton) {
        observer.unobserve(currentButton);
      }
    };
  }, []);

  return (
    <section className={styles.heroWrapper}>
      <div className={cx(styles.hero, isButtonVisible && styles._isButtonVisible)}>

        {isMobile && (
          <>
            <Okko className={styles.logo} />

            <div className={styles.heroText}>
              <img
                src={titleImg}
                className={styles.title}
                alt='Мамонты'
              />
              <p className={styles.premiera}>Премьера сериала</p>
              <p className={styles.subtitle}>Cезон охоты на&nbsp;мошенников</p>
            </div>
          </>
        )}

        {!isMobile && (
          <div className={styles.heroTextWrapper}>
            <div className={styles.heroText}>
              <Okko className={styles.logo} />
              <p className={styles.premiera}>Премьера сериала</p>
              <img
                src={titleImg}
                className={styles.title}
                alt='Мамонты'
              />
              <p className={styles.subtitle}>Cезон охоты на мошенников</p>
            </div>
          </div>
        )}

        <div className={styles.button} ref={buttonRef}>
          <WatchButton link={link} />
        </div>
        <div className={styles.animatedBtn} >
          <WatchButton link={link} />
        </div>
      </div>
    </section>
  );
}

Hero.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(skip(Hero));
