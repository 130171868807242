import deepmerge from 'core/libs/deepmerge';

export default function mamontyTheme(atoms) {
  const socializator = {
    fixedPosition: false,
    buttonHeight: 32,
  };

  return deepmerge({
    controls: {
      socializator,
    },
  }, atoms);
}
